import React from "react";
// import '../../../css/components/';

const Landing = () => {
    return (
        <div>
            landing
        </div>
    )
}

export default Landing;