import React, {useEffect, useState} from "react";
import '../../../../css/components/Modals/Register/index.css';
import AuthInput from "../../uiElements/AuthInput";
import {Link, useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../utils/useAuthContext";
import axios from "axios";
import cfg from "../../../utils/cfg";
import Login from "../Login";
import useModal from "../../../utils/useModal";
import {usePreferences} from "../../../utils/PreferencesProvider";
// import {registry} from "chart.js";

const Register = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { logIn } = useAuthContext();
    const { open, close } = useModal();
    const {texts} = usePreferences();

    const [formData, setFormData] = useState({
        login: '',
        password: '',
        email: '',
        promocode: ''
        // promo_code: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'phone' ? value : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(`form data: ${JSON.stringify(formData)}`)
        try {
            console.log(formData);
            const response = await axios.post(`${cfg.userURL}/auth/signup`, formData);
            console.log(response)
            if (response.status === 201 || response.status === 200) {
                try {
                    const response = await logIn({login: formData.login, password: formData.password});
                    console.log(response)
                    if (response.status === 201 || response.status === 200) {
                        navigate('/');
                        console.log('200');
                        setError(null)
                        close();
                    } else {
                        setError(response.data.errors[0].message);
                        console.log('error: 15')
                    }
                } catch (error) {
                    console.log(`error: 14`)
                }
            } else {
                console.log(response);
                console.log(`error: 13`)
            }
        } catch (error) {
            if (error.response.status === 401) {
                setError('Некорректный промокод')
            }
            if (error.response.status === 409) {
                setError('Пользователь с таким логином уже существует')
            }
            console.log(`error: 12`)
            console.log(error)
            // setError(error.response.data.errors[0].message);
        }
    };

    return (
        <>
            <div className="auth-form-wrapper">
                <form className="auth-form" onSubmit={handleSubmit}>
                    <div className="auth-top-text-container">
                        <h1>{texts.register}</h1>
                        <span>{texts.registerAction}!</span>
                    </div>
                    <AuthInput
                        isRequired={true}
                        type="text"
                        name="login"
                        value={formData.login}
                        onChange={handleChange}
                        placeholder={texts.login} />
                    <AuthInput
                        isRequired={true}
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="E-mail" />
                    <AuthInput
                        isRequired={true}
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder={texts.password} />
                    <AuthInput
                        isRequired={false}
                        type="text"
                        name="promocode"
                        value={formData.promocode}
                        onChange={handleChange}
                        placeholder={texts.promo} />
                    <div className="reg-checkbox-container">
                        <input id="reg-req" type="checkbox" required={true}/>
                        <label htmlFor="reg-req">{texts.iAgree}</label>
                    </div>
                    <button className="auth-next-button" type="submit">{texts.register}</button>
                    {error ? <span className="auth-error">{error}</span> : ''}
                    <div className="horizontal-line"></div>
                    <span className="auth-bottom">{texts.haveAccount}?
                        <button onClick={() => open(<Login />)}>{texts.loginAction}</button>
                    </span>
                </form>
            </div>
        </>
    );

}

export default Register;