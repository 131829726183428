import React from "react";
// import '../../../css/components/';
import Main from "../Main";
import {useParams} from "react-router-dom";
import useModal from "../../utils/useModal";
import Deposit from "../Modals/Deposit";

const OpenPaymentModal = () => {
    const { id } = useParams();
    const { open } = useModal();
    open(<Deposit id={id} />, id);
    return (
        <>
            <Main />
        </>
    )
}

export default OpenPaymentModal;