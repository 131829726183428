import React from "react";
import '../../../../css/components/Modals/Language/index.css';
import {usePreferences} from "../../../utils/PreferencesProvider";
import useModal from "../../../utils/useModal";

const Language = () => {
    const { changeLanguage, language, texts } = usePreferences();
    const { close } = useModal();

    const setLanguageHandler = (language) => {
        changeLanguage(language);
        close();
    }

    return(
        <div className="modal-language">
            <div className="modal-top-text">
                <span>{texts.language}</span>
            </div>
            <div className="language-modal-container">
                <button className={`${language === 'RU' && 'active'}`} onClick={() => setLanguageHandler('RU')}>RU</button>
                <button className={`${language === 'ENG' && 'active'}`} onClick={() => setLanguageHandler('ENG')}>ENG</button>
            </div>
        </div>
    )
}

export default Language;