import React, {useEffect, useState} from "react";
import axios from "axios";
import cfg from "../../../../utils/cfg";
import TopElement from "../TopElement";

const Top = () => {
    const [top, setTop] = useState([]);

    const getTop = async () => {
        const { data } = await axios.get(`${cfg.defaultURL}/topbets`);
        console.log(data)
        setTop(data);
    };

    useEffect(() => {
        getTop();
    }, []);

    return (
        <>
            {top.map((element, key) => (
                <TopElement
                    key={key}
                    username={element.username}
                    multiplier={element.multiplier}
                    bet={element.bet}
                />
            ))}
        </>
    )
}

export default Top;