import axios from "axios"
import React, { useState } from "react"
import cfg from "../../../utils/cfg"
import '../../../../css/components/AdminPanel/DeleteUser/index.css'
function DeleteUser() {
    const [login, setLogin] = useState(null)

    return (
        <form className="deleteUser">
            <h3>Удалить пользователя</h3>
            <input type="text" onChange={(e)=>{setLogin(e.target.value)}} placeholder="login"/>
            <button type="reset" onClick={()=>{
                 axios.delete(`${cfg.apiURL}/adminpanel`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        login: login
                    }
                }).then((res)=>{
                    setLogin(null)
                    if (res.status === 200) {
                        alert('Пользователь удалён')
                    }
                }).catch(e => {
                    if (e.response && e.response.status === 404) {
                        alert('Пользователь не найден');
                    } else {
                        alert('An error occurred');
                    }
                    console.error(e);
                })
                }}
            >Удалить</button>
        </form>
    )
}

export default DeleteUser