import React from "react";
// import loadingAnimation from "../../../../media/svg/game-loading.svg";
import loadingAnimation from './../../../../../media/game-loading.c544881eb170e73349e4c92d1706a96c.svg';
import {usePreferences} from "../../../../utils/PreferencesProvider";

const GameFinish = () => {
    const { texts } = usePreferences();

    return (
        <div className="game-awaiting-container">
            <img src={loadingAnimation} alt=""/>
            <span>{texts.gameEndAwaiting}</span>
        </div>
    )
}

export default GameFinish;