import axios from "axios"
import React, { useState } from "react"
import cfg from "../../../utils/cfg"
import '../../../../css/components/AdminPanel/AddAdmin/index.css'
function AddAdmin() {
    const [login, setLogin] = useState(null)

    return (
        <form className="formAddAdmin">
            <h3>Назначить админа</h3>
            <input type="text" onChange={(e)=>{setLogin(e.target.value)}} placeholder="login"/>
            <button type="reset" onClick={()=>{
                axios.post(`${cfg.apiURL}/adminpanel`,{login}).then((res)=>{
                    setLogin(null)
                    if (res.status === 200) {
                        alert('Админ назначен')
                    } else alert('Ошибка')
                }).catch(e => {
                    if (e.response && e.response.status === 404) {
                        alert('Пользователь не найден');
                    }
                    if (e.response && e.response.status === 400) {
                        alert('Пользователь уже админ');
                    } else {
                        alert('An error occurred');
                    }
                    console.error(e);
                })
            }}
            >Назначить</button>
        </form>
    )
}

export default AddAdmin