import React from "react";
// import './../../../css/components/';

const NotFound = () => {
    return(
        <div>
            404
        </div>
    )
}

export default NotFound;