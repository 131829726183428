import React from 'react';
import './../../../css/components/NotificationsContainer/index.css';
import Notification from "../Notification";
const NotificationContainer = ({ notifications, removeNotification }) => {
    console.log(notifications);
    return (
        <div className="notification-container">
            {notifications.map(notification => (
                <Notification
                    key={notification.id}
                    message={notification.message}
                    type={notification.type || "notype"}
                    onClose={() => removeNotification(notification.id)}
                />
            ))}
        </div>
    );
};

export default NotificationContainer;
