export const languageData = {
    RU: {
        current: 'РУ',
        language: 'Язык',
        test: 'ТЕСТ',
        gameId: 'ID Игры',
        htp: 'Как играть',
        htp1: 'Введите нужную сумму и нажмите кнопку Ставка',
        htp2: 'Следи как твой Счастливчик Джо планирует а коэффициент растет',
        htp3: 'Выводи прежде чем Счастливчик Джо исчезнет и выиграй в X раз больше!!',
        deposit: 'Пополнить',
        withdrawal: 'Вывод',
        gameLimits: 'Лимиты игры',
        guarantees: 'Гарантии',
        support: 'Поддержка',
        logOut: 'Выход',
        all: 'Все',
        my: 'Мои',
        top: 'Топ',
        totalBets: 'Всего ставок',
        betsAwaiting: 'Ожидаются ставки',
        noBetsYet: 'У вас еще нет ставок',
        autoBet: 'Авто ставка',
        autoWithdrawal: 'Авто вывод',
        take: 'Забрать',
        bet: 'Ставка',
        gameStartAwaiting: 'Ожидание начала игры',
        gameEndAwaiting: 'Ожидание конца игры',
        flew: 'УЛЕТЕЛ',
        cardRu: 'Карта (Россия)',
        cardUa: 'Карта (Украина)',
        cardKz: 'Карта (Казахстан)',
        fundsNotReceived: 'Средства еще не зачислены',
        error: 'Произошла ошибка',
        sumToPay: 'К оплате',
        bankForSbp: 'Банк для СБП',
        cardNumber: 'Номер карты',
        number: 'Номер',
        phone: 'телефон',
        copy: 'скопировано',
        copied: 'скопировать',
        checkPayment: 'Проверить платеж',
        youEnteredPromo: 'Вы ввели промокод',
        depositBonus: 'бонус к пополнению',
        sumIn: 'Сумма в',
        promo: 'Промокод',
        activate: 'Активировать',
        yourNextDepositWillBeIncreased: 'Ваш следующий платеж будет увеличен на',
        promoActivated: 'Промокод активирован! На баланс зачислено',
        promoNotFound: 'Промокод не найден',
        activatedDepositBonus: 'активирован, бонус депозита',
        limits: 'Лимиты',
        minBet: 'Минимальная ставка',
        maxBet: 'Максимальная ставка',
        login: 'Логин',
        gladToSeeYou: 'Рады вас видеть',
        password: 'Пароль',
        noAccount: 'Нет аккаунта',
        haveAccount: 'Уже есть аккаунт',
        registerAction: 'Создать аккаунт',
        loginAction: 'Вход в аккаунт',
        register: 'Регистрация',
        iAgree: 'Подтверждаю, что мне больше 18',
        g1: 'Мы играем честно. Хочешь узнать как это работает?',
        g2: 'Коэффициент выигрыша стартует с 1х и растет все выше и выше пока Счастливчик Джо поднимается вверх.',
        g3: 'Выигрышная сумма равняется коэффициенту в момент когда игрок сделал вывод умноженному на сумму его ставки.',
        g4: 'Перед тем как начать раунд наш генератор беспристрастно генерирует случайные числа коэффициента при котором Счастливчик Джо улетает. В любой момент честность расчета коэффициента для конкретного раунда можно проверить нажатием на кнопку в окне истории.',
        g5: 'Также имеется возможность проверить и изменить настройки честной игры. Для этого необходимо зайти в «Меню Игры» и выбрать пункт «Настройки PROVABLY FAIR».',
        g6: 'Технические гарантии нашей честности',
        g7: 'Однако не забывайте что у вас есть ограничения по времени. Вам нужно вывести средства до того как улетит Счастливчик Джо иначе вы потеряете свою ставку. Игра в Lucky Jet – азарт в чистом виде! Здесь вы рискуете и побеждаете. Все зависит от вас!',
        withdrawalError: 'Произошла ошибка вывода. Обратитесь в техническую поддержку.',
        withdrawalSuccess: 'Вывод успешно поставлен на обработку. Средства поступят на счет в течении 60 минут.',
        withdrawalVerification: 'Вам необходимо пройти верификацию аккаунта. Обратитесь в техническую поддержку.',
        withdrawalNumber: 'Номер счета',
        pickedWin: 'Успели',
        pickedLoss: 'Не успели',
        youWon: 'Вы выиграли!',
        youLost: 'Вы проиграли',
    },
    ENG: {
        current: 'ENG',
        language: 'Language',
        test: 'TEST',
        gameId: 'Game ID',
        htp: 'How To Play',
        htp1: 'Enter the required amount and click the Bet button',
        htp2: 'Watch how your Lucky Joe plans and the coefficient grows',
        htp3: 'Withdraw before Lucky Joe disappears and win X times more!',
        deposit: 'Deposit',
        withdrawal: 'Withdrawal',
        gameLimits: 'Game Limits',
        guarantees: 'Guarantees',
        support: 'Support',
        logOut: 'Log Out',
        all: 'ALl',
        my: 'My',
        top: 'Top',
        totalBets: 'Total bets',
        betsAwaiting: 'Awaiting bets',
        noBetsYet: 'You dont have bets yet',
        autoBet: 'Auto Bet',
        autoWithdrawal: 'Auto Withdrawal',
        take: 'Pickup',
        bet: 'Bet',
        gameStartAwaiting: 'Awaiting the game to start',
        gameEndAwaiting: 'Awaiting the game to end',
        flew: 'FLEW AWAY',
        cardRu: 'Card (Russia)',
        cardUa: 'Card (Ukraine)',
        cardKz: 'Card (Kazakhstan)',
        fundsNotReceived: 'We didnt receive funds yet',
        error: 'Error occurred',
        sumToPay: 'Sum to pay',
        bankForSbp: 'Bank for SBP',
        cardNumber: 'Card number',
        number: 'Number',
        phone: 'phone',
        copy: 'copied',
        copied: 'copy',
        checkPayment: 'Check payment',
        youEnteredPromo: 'You entered promo',
        depositBonus: 'deposit bonus',
        sumIn: 'Sum in',
        promo: 'Promo code',
        activate: 'Activate',
        yourNextDepositWillBeIncreased: 'Your next deposit will be increased by',
        promoActivated: 'Promo activated! Deposited',
        promoNotFound: 'Promo not found',
        activatedDepositBonus: 'activated, deposit bonus is',
        limits: 'Limits',
        minBet: 'Maximal bet',
        maxBet: 'Minimal bet',
        login: 'Login',
        gladToSeeYou: 'Glad to see you',
        password: 'Password',
        noAccount: 'Dont have an account',
        haveAccount: 'Already have an account',
        registerAction: 'Create an account',
        loginAction: 'Login to account',
        register: 'Register',
        iAgree: 'I agree that Im over 18',
        g1: 'We play fair. Do you want to know how it works?',
        g2: 'The winning odds start at 1x and go higher and higher as Lucky Joe climbs up.',
        g3: 'The winning amount is equal to the odds at the time the player made the withdrawal multiplied by the amount of his bet.',
        g4: 'Before starting a round, our generator impartially generates random numbers of the coefficient at which Lucky Joe flies away. At any time, the fairness of the coefficient calculation for a particular round can be checked by clicking on the button in the history window.',
        g5: 'It is also possible to check and change fair play settings. To do this, go to the “Game Menu” and select “PROVABLY FAIR Settings”.',
        g6: 'Technical guarantees of our integrity',
        g7: 'However, do not forget that you have time limits. You need to withdraw your funds before Lucky Joe flies away otherwise you will lose your bet. Playing Lucky Jet is pure excitement! This is where you take risks and win. Everything depends on you!',
        withdrawalError: 'An withdrawal error occurred. Contact technical support.',
        withdrawalSuccess: 'The withdrawal has been successfully submitted for processing. The funds will be credited to your account within 60 minutes.',
        withdrawalVerification: 'You need to go through account verification. Contact technical support.',
        withdrawalNumber: 'Number',
        pickedWin: 'Picked in time',
        pickedLoss: 'You lost the bet',
        youWon: 'You won!',
        youLost: 'You lost',
    }
};