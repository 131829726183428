import React from "react";
import '../../../../css/components/Modals/Trust/index.css';
import {usePreferences} from "../../../utils/PreferencesProvider";

const Trust = () => {
    const {texts} = usePreferences();
    return(
        <div className="modal-trust">
            <div className="modal-top-text">
                <span>{texts.guarantees}</span>
            </div>
            <div className="modal-trust-container">
                <span className="trust-headline">{texts.g1}</span>
                <ul>
                    <li className="trust-text">{texts.g2}</li>
                    <li className="trust-text">{texts.g3}</li>
                    <li className="trust-text">{texts.g4}</li>
                    <li className="trust-text">{texts.g5}</li>
                </ul>
                <span className="trust-headline">{texts.g6}</span>
                <span className="trust-text">{texts.g7}</span>
            </div>
        </div>
    )
}

export default Trust;