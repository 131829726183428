import React, {createContext, useState, useContext, useEffect, useRef} from 'react';
// import bgSound from './../media/sounds/bg.mp3';
// import flySound from './../media/sounds/fly.mp3';
// import beepSound from './../media/sounds/beep.mp3';

import bgSound from '../../media/bgSound.mp3';
import flySound from '../../media/fly.mp3';
import beepSound from '../../media/beep.mp3';

const SoundContext = createContext();

export const SoundProvider = ({ children }) => {
    const [isSoundOn, setIsSoundOn] = useState(false);
    const bgAudio = useRef(new Audio(bgSound)); // Create a ref to the audio object

    useEffect(() => {
        const audio = bgAudio.current;
        audio.loop = true;
        if (isSoundOn) {
            audio.play();
        } else {
            audio.pause();
            audio.currentTime = 0; // Reset the audio to start
        }

        // Cleanup function
        return () => {
            audio.pause();
            // Do not set audio to null here
        };

    }, [isSoundOn]);


    const playSound = (soundFile) => {
        if (!isSoundOn) return;

        let audio;
        switch (soundFile) {
            case 'fly':
                audio = new Audio(flySound);
                break;
            case 'beep':
                audio = new Audio(beepSound);
                break;
            default:
                console.log('Sound not found:', soundFile);
                return;
        }
        audio.play().catch(error => console.error("Audio play failed:", error));
    };

    const toggleSound = () => {
        setIsSoundOn(!isSoundOn);
    };

    return (
        <SoundContext.Provider value={{ isSoundOn, toggleSound, playSound }}>
            {children}
        </SoundContext.Provider>
    );
};

export const useSound = () => {
    const context = useContext(SoundContext);
    if (context === undefined) {
        throw new Error('useSound must be used within a SoundProvider');
    }
    return context;
};
