import React, {useState} from "react";
import '../../../../css/components/Modals/DepositChoice/index.css';
import {useAuthContext} from "../../../utils/useAuthContext";
import axios from "axios";
import cfg from "../../../utils/cfg";
import DepositChoiceElement from "../DepositChoice/DepositChoiceElement/index";



import card from './../../../../media/118cb4f28430e03efd5242dd2e391323.c1a58649bdff0044d9b033ba24321091.svg';
import eth from './../../../../media/2e48145389fc8bb6eda3272f7958956a.dc85ceeca9962d2b8d79119726e6db7e.svg';
import btc from './../../../../media/612712e338dfbb5883e4f9607900a908.f6903473b7922c28fbae35684a36b3ef.svg';
import usdt from './../../../../media/f9c7a3037a4d8ba25b89493efa57d229.eb4f8344e7ff0452737b954c90278aaa.svg';

import {usePreferences} from "../../../utils/PreferencesProvider";

const Withdrawal = () => {
    const [activeDepositChoice, setActiveDepositChoice] = useState('Bank (Russia)');
    const depositMapping = {
        'Bank (Russia)': ['card', card],
        'Bank (Ukraine)': ['card_ua', card],
        'Bank (Kazakhstan)': ['card_kz', card],
        'Ethereum': ['eth', eth],
        'USDT': ['usdt', usdt],
        'Bitcoin': ['btc', btc],
    };
    const deposits = Object.keys(depositMapping);
    const [message, setMessage] = useState(null);
    const { getToken } = useAuthContext();
    const {texts} = usePreferences();
    const responseMap = {
        'error': [texts.withdrawalError, 'error'],
        'success': [texts.withdrawalSuccess, 'success'],
        'verification': [texts.withdrawalVerification, 'warn'],
    }
    const activeChoiceHandler = (name) => {
        setActiveDepositChoice(name);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const amount = Number(document.getElementById('deposit-amount').value);
        const recipient = document.getElementById('recipient').value;
        axios.post(`${cfg.apiURL}/payout`, {
            amount: amount,
            card: recipient,
            type: depositMapping[activeDepositChoice][0]
        }, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        }).then((response) => {
            console.log(response);
            if (response.status === 200) {
                setMessage(responseMap['success']);
            }

            if (response.status === 403) {
                setMessage(responseMap['verification']);
            }
            if (response.status === 500) {
                setMessage(responseMap['error']);
            }
            // setMessage(responseMap[response.data.type]);
        }).catch((error) => {
            const displayedError = error.response?.data.message || 'An error occurred';
            // setMessage([displayedError, 'error'])
            setMessage([displayedError, 'error'])
            console.log(error)
        })
    }

    return (
        <div className="deposit-choice">
            <div className="deposit-top">
                <span>{texts.withdrawal}</span>
            </div>
            <div className="deposit-choice-main">
                <div className="deposit-choice-main-left">
                    {deposits.map((deposit) => (
                        <DepositChoiceElement
                            key={deposit}
                            name={deposit}
                            image={depositMapping[deposit][1]}
                            isChecked={deposit === activeDepositChoice}
                            onChange={() => activeChoiceHandler(deposit)}
                        />
                    ))}
                </div>
                <form onSubmit={handleSubmit} className="deposit-choice-main-right">
                    <input id="deposit-amount" required={true} className="deposit-input" type="number" placeholder={`${texts.sumIn} ₽`}/>
                    <input id="recipient" required={true} className="deposit-input" type="text" placeholder={texts.withdrawalNumber}/>
                    <input className="deposit-submit" type="submit" value={texts.withdrawal}/>
                    {message && <span className={`deposit-error ${message[1]}`}>{message[0]}</span>}
                </form>
            </div>
        </div>
    )
}

export default Withdrawal;