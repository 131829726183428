import React, {useState} from "react";
import AuthInput from "../../uiElements/AuthInput";
import {Link, useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../utils/useAuthContext";
import useModal from "../../../utils/useModal";
import Register from "../Register";
import '../../../../css/components/Modals/Register/index.css';
import {usePreferences} from "../../../utils/PreferencesProvider";

const Login = () => {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({ login: '', password: '' });
    const { logIn } = useAuthContext();
    const { open, close } = useModal();
    const { texts } = usePreferences();

    const handleChange = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(loginData);
            const response = await logIn(loginData);
            if (response.status === 200) {
                navigate('/');
                setError(null)
                console.log('success');
                close();
            } else {
                if (response.data.error.response.status === 401) {
                    setError('Неправильный логин или пароль')
                    console.log(error,loginData);
                }
                // setError(response.data.error.response.data.error);
                // console.log('not success')
            }
        } catch (error) {
            setError(error);
        }
    };


    return (
        <form className="auth-form" onSubmit={handleSubmit}>
            <div className="auth-top-text-container">
                <h1>{texts.login}</h1>
                <span>{texts.gladToSeeYou}!</span>
            </div>
            <AuthInput isRequired={true} type="text" name="login" value={loginData.login}
                       onChange={handleChange} placeholder={texts.login}/>
            <AuthInput isRequired={true} type="password" name="password"
                       value={loginData.password} onChange={handleChange}
                       placeholder={texts.password}/>
            <button className="auth-next-button" type="submit">{texts.login}</button>
            {error ? <span className="auth-error">{error}</span> : ''}
            <div className="horizontal-line"></div>
            <span className="auth-bottom">{texts.noAccount}?
                <button onClick={() => open(<Register/>)}>{texts.registerAction}!</button>
            </span>
        </form>
    );
}

export default Login;