import React from "react";
import '../../../../css/components/Modals/Limits/index.css';
import {usePreferences} from "../../../utils/PreferencesProvider";

const Limits = () => {
    const { texts } = usePreferences();
    return(
        <div className="modal-limits">
            <div className="modal-top-text">
                <span>{texts.limits}</span>
            </div>
            <div className="modal-limits-container">
                <div className="modal-limit-container">
                    <span>{texts.minBet}</span>
                    <span>100₽</span>
                </div>
                <div className="modal-limit-container">
                    <span>{texts.maxBet}</span>
                    <span>100,000₽</span>
                </div>
            </div>
        </div>
    )
}

export default Limits;