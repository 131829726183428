import React from "react";
import Graph from "../../Graph";
import {useUserSocketData} from "../../../utils/useUserSocket";
import '../../../../css/components/Main/MainArea/index.css';
import GameFinish from "./GameFinish";
import RoundWaiting from "./RoundWaiting";

const MainArea = () => {
    const { gameState } = useUserSocketData();

    let renderedComponent;
    if (gameState.state === 2) {
        renderedComponent = <Graph xValue={gameState.multiplier} />
    } else if (gameState.state === 3) {
        renderedComponent = <GameFinish />
    } else if (gameState.state === 1) {
        renderedComponent = <RoundWaiting />
    } else {
        renderedComponent = <div className="main-element-container">Techincal shokoladki</div>
    }
    return (
        <>
            {renderedComponent}
        </>
    )
}

export default MainArea;