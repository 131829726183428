import React from 'react';
import useModal from "../../utils/useModal";
import './../../../css/components/Modals/index.css';
import {Link} from "react-router-dom";

const Modals = () => {
    const { isOpen, open, close, modalBody, withID } = useModal();
    return (
        <div className={`modal-backdrop ${isOpen ? 'opened' : 'closed'}`}>
            <div className="modal-container">
                {withID ? (
                    <Link className="modal-close" to="/" onClick={close}>
                        <i className="icon close"></i>
                    </Link>
                ) : (
                    <button className="modal-close" onClick={close}>
                        <i className="icon close"></i>
                    </button>
                )}
                {modalBody}
            </div>
        </div>
    );
};

export default Modals;