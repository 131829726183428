import React, {useEffect, useState} from "react";
import '../../../css/components/Header/index.css';
import {useAuthContext} from "../../utils/useAuthContext";
import Login from "../Modals/Login";
import Register from "../Modals/Register";
import useModal from "../../utils/useModal";
import logo from '../../../media/logo.png';
import HowToPlay from "./HowToPlay";
import {useUserSocketData} from "../../utils/useUserSocket";
import Wallet from "./Wallet";
import {useSound} from "../../utils/useSounds";
import Menu from "./Menu";
import Language from "../Modals/Language";
import {usePreferences} from "../../utils/PreferencesProvider";

const Header = () => {
    const { user, logOut } = useAuthContext();
    const { texts } = usePreferences();
    const { open } = useModal();
    const { currentGameID, userBalance } = useUserSocketData();
    const { isSoundOn, toggleSound } = useSound();
    const [headerModalUpdate, setHeaderModalUpdate] = useState(null);
    useEffect(()=>{

    },[user])
    return(
        <header>
            <div className="header-left">
                <img src={logo} alt=""/>
            </div>
            <div className="header-right">
                {!user?.admin && 
                <div className="button-wrapper">
                    <div className="header-button game-id">
                        <span>{texts.gameId}:</span>
                        <span>#{currentGameID}</span>
                    </div>
                </div>}
                <div className="button-wrapper">
                    <button className="header-button sound-btn" onClick={toggleSound}>
                        <i className="icon sound"></i>
                        {isSoundOn ? <></> : <div className="sound-off-line"></div>}
                    </button>
                </div>
                {!user?.admin &&<div className="button-wrapper">
                    <HowToPlay
                        headerModalUpdate={headerModalUpdate}
                        setHeaderModalUpdate={setHeaderModalUpdate}
                    />
                </div>}
                <div className="button-wrapper">
                    <button className="header-button game-id" onClick={() => open(<Language />)}>{texts.current}</button>
                </div>
                {!user?.admin && <div className="button-wrapper">
                    {user ? (
                        <Wallet
                            headerModalUpdate={headerModalUpdate}
                            setHeaderModalUpdate={setHeaderModalUpdate}
                        />
                    ) : (
                        <button onClick={() => open(<Login />)} className="header-button">
                            <i className="icon wallet"></i>
                            <span>{userBalance}$</span>
                        </button>
                    )}

                    <div className="ver-sep"></div>
                    <Menu
                        headerModalUpdate={headerModalUpdate}
                        setHeaderModalUpdate={setHeaderModalUpdate}
                    />
                </div>}
                {user ? (
                        <div className="button-wrapper">
                            <button className="header-button sound-btn" onClick={logOut}>
                                <i className="icon log-out"></i>
                            </button>
                        </div>
                ) : (
                    <div className="button-wrapper">
                        <button className="header-button auth" onClick={() => open(<Login/>)}>{texts.login}</button>
                        <div className="ver-sep auth"></div>
                        <button className="header-button auth" onClick={() => open(<Register/>)}>{texts.register}</button>
                    </div>
                )}
            </div>
        </header>
    )
}

export default Header;