import axios from "axios"
import React, { useState } from "react"
import cfg from "../../utils/cfg"
import FormCardNum from "./FormCardNum/FormCardNum"
import AddAdmin from "./AddAdmin/AddAdmin"
import DeleteUser from "./DeleteUser/DeleteUser"
import '../../../css/components/AdminPanel/index.css'
function AdminPanel() {
    const [numCard, setNumCard] = useState(null)
    const [type, setType] = useState('rub')

    return (
        <main className="adminForm">
        <FormCardNum/>
        <AddAdmin/>
        <DeleteUser/>
        </main>
    )
}

export default AdminPanel