import React, {createContext, useContext, useEffect, useState} from 'react';
import NotificationContainer from "../components/NotificationsContainer";
import {useUserSocketData} from "./useUserSocket";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (message, type = "notype") => {
        const id = new Date().getTime();
        console.log(message);
        setNotifications(prevNotifications => [...prevNotifications, { id, message, type }]);
    };

    const removeNotification = (id) => {
        setNotifications(notifications.filter(notification => notification.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ addNotification }}>
            {children}
            <NotificationContainer
                notifications={notifications}
                removeNotification={removeNotification}
            />
        </NotificationContext.Provider>
    );
};
