import React from "react";
import '../../../../../css/components/Main/leftComponents/TopElement/index.css';

const TopElement = ({username, multiplier, bet}) => {
    return (
        <div className="top-element">
            <div className="pfp">
                {username[0]}
            </div>
            <span className="name">{username}</span>
            <span className="bet">{bet.toFixed(1)}₽</span>
            <div className="multiplier">
                <span>
                    {multiplier.toFixed(1)}x
                </span>
            </div>
            <span className="win">{(bet * multiplier).toFixed(1)}₽</span>
        </div>
    )
}

export default TopElement;