import React from "react";
import {useUserSocketData} from "../../../utils/useUserSocket";
import GameValueElement from "./GameValueElement";

const ValuesHistory = () => {
    const { games } = useUserSocketData()
    return (
        <>
            {[...games].reverse().map((game) => (
                <GameValueElement key={game.id} id={game.id} value={game.multiplier} />
            ))}
        </>
    )
}

export default ValuesHistory;