import axios from "axios"
import React, { useState } from "react"
import cfg from "../../../utils/cfg"
import '../../../../css/components/AdminPanel/FormCardNum/index.css'
function FormCardNum() {
    const [numCard, setNumCard] = useState('')
    const [type, setType] = useState('rub')

    return (
        <form className="formCardNum">
            <h3>Изменение номера кошелька</h3>
        <input type="text" onChange={(e)=>{setNumCard(e.target.value)}}/>
        <select name="type" id="type" onChange={(e)=>{setType(e.target.value);console.log(e.target.value);}}>
            <option value={'rub'}>Bank (Russia)</option>
            <option value={'ukr'}>Bank (Ukraine)</option>
            <option value={'kaz'}>Bank (Kazakhstan)</option>
            <option value={'btc'}>Bitcoin</option>
            <option value={'eth'}>Ethereum</option>
            <option value={'usdt'}>USDT</option>
        </select>
        <button type="reset" onClick={()=>{
            if (!numCard.trim()) {
                return alert('Полн номера карты заполнено не корректно')
            }
            axios.patch(`${cfg.apiURL}/adminpanel`,{card: numCard.trim(), type}).then((res)=>{
                
                alert('Данные изменены')
                setNumCard('')
                setType('rub')
            })
            }}
        >Изменить</button>
        </form>
    )
}

export default FormCardNum