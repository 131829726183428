import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import cfg from './cfg';
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate();

    const setToken = (token) => {
        const now = new Date();
        const item = {
            value: token,
            expiry: now.getTime() + (2 * 7 * 24 * 60 * 60 * 1000), // 2 weeks in milliseconds
        };
        localStorage.setItem('authToken', JSON.stringify(item));
    };

    const getToken = () => {
        const tokenString = localStorage.getItem('authToken');
        if (!tokenString) {
            return null;
        }

        try {
            const tokenObj = JSON.parse(tokenString);
            const now = new Date();

            if (now.getTime() > tokenObj.expiry) {
                localStorage.removeItem('authToken');
                return null;
            }

            return tokenObj.value;
        } catch (error) {
            localStorage.removeItem('authToken');
            return null;
        }
    };

    const logIn = async (loginData) => {
        try {
            // const response = await axios.post(`${cfg.userURL}/auth`, loginData);
            const response = await axios.post(`${cfg.userURL}/auth/login`, loginData);
            const { token, user, ...userData } = response.data;
            console.log(response);
            setToken(token);
            setUser(user);
            return response;
        } catch (error) {
            // console.error(error);

            return { data: { success: false, error } };
        }
    };

    const logOut = async () => {
        try {
            // await axios.get(`${cfg.userURL}/logout`);
            localStorage.removeItem('authToken');
            setUser(null);
            navigate('/');
        } catch (error) {
            console.error(error);
        }
    };

    const checkAuth = async () => {
        const token = getToken();
        if (!token) {
            setUser(null);
            return;
        }
        try {
            // const response = await axios.post(`${cfg.userURL}/isAuth`, { token });

            const response = await axios.get(`${cfg.userURL}/user`,{headers:{Authorization:`Bearer ${token}`}});
            setUser(response.data);
        } catch (error) {
            console.error("Auth check error:", error);
            localStorage.removeItem('authToken');
            setUser(null);
            navigate('/');
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ user, logIn, logOut, getToken,setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);