import React, {useEffect, useState} from "react";
import UserElement from "../UserElement";
import { useUserSocketData } from "../../../../utils/useUserSocket";
import '../../../../../css/components/Main/leftComponents/index.css';
import {usePreferences} from "../../../../utils/PreferencesProvider";

const All = () => {
    const { displayedBets, currentGameID } = useUserSocketData();
    const { texts } = usePreferences()

    return(
        <>
            <div className="bets-info">
                <div className="total-bets">
                    <span>{texts.totalBets}:</span>
                    <span>{(displayedBets.length) * 9}</span>
                </div>
                <div className="bets-info-game-id">
                    <span>{texts.gameId}:</span>
                    <span>#{currentGameID}</span>
                </div>
            </div>
            {displayedBets.length > 0 ? (
                displayedBets.map((bet, key) => (
                    <UserElement
                        key={key}
                        pfp={bet.name}
                        username={bet.name}
                        initSum={bet.bet}
                        multiplier={bet.multiplier}
                        winSum={bet.bet * bet.multiplier}
                    />
                ))
            ) : (
                <div className="bets-await">
                    <span>{texts.betsAwaiting}</span>
                </div>
            )}
        </>
    )
}

export default All;