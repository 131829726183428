import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './js/App';
import reportWebVitals from './js/reportWebVitals';
import { AuthProvider } from "./js/utils/useAuthContext";
import {BrowserRouter} from "react-router-dom";
import {ModalProvider} from "./js/utils/ModalContext";
import {SoundProvider} from "./js/utils/useSounds";
import {UserSocketDataProvider} from "./js/utils/useUserSocket";
import {NotificationProvider} from "./js/utils/NotificationProvider";
import {PreferencesProvider} from "./js/utils/PreferencesProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <PreferencesProvider>
            <NotificationProvider>
                
                <AuthProvider>
                    
                    <UserSocketDataProvider>
                        
                        <ModalProvider>

                            <SoundProvider>
                                <App />
                            </SoundProvider>
                        </ModalProvider>
                    </UserSocketDataProvider>
                </AuthProvider>
            </NotificationProvider>
        </PreferencesProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
