import React from "react";
import '../../../../../css/components/Main/leftComponents/index.css';
import {useUserSocketData} from "../../../../utils/useUserSocket";
import MyBetElement from "../MyBetElement";
import {useAuthContext} from "../../../../utils/useAuthContext";
import {usePreferences} from "../../../../utils/PreferencesProvider";

const My = () => {
    const { userBets, currentGameID } = useUserSocketData();
    const { user } = useAuthContext();
    const { texts } = usePreferences()
    return(
        <>
            <div className="bets-info">
                <div className="total-bets">
                    <span>{texts.totalBets}:</span>
                    <span>{userBets.length}</span>
                </div>
                <div className="bets-info-game-id">
                    <span>{texts.gameId}:</span>
                    <span>#{currentGameID}</span>
                </div>
            </div>

            {user && userBets.length > 0 ? (
                userBets.map((bet, key) => (
                    <MyBetElement
                        amount={bet.amount}
                        gameId={bet.gameId}
                        multiplier={bet.multiplier || 0}
                        isWin={bet.win}
                        isActive={bet.active}
                        username={user.login.toUpperCase()}
                        key={key}
                    />
                ))
            ) : (
                <div className="bets-await">
                    <span>{texts.noBetsYet}</span>
                </div>
            )}
        </>
    )
}

export default My;