import React, {useEffect, useState} from "react";
import '../../../../css/components/Header/Wallet/index.css';
import { useUserSocketData } from "../../../utils/useUserSocket";
import useModal from "../../../utils/useModal";
import DepositChoice from "../../Modals/DepositChoice";
import Withdrawal from "../../Modals/Withdrawal";
import {usePreferences} from "../../../utils/PreferencesProvider";

const Wallet = ({headerModalUpdate, setHeaderModalUpdate}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { userBalance } = useUserSocketData();
    const { open } = useModal();
    const { texts } = usePreferences();

    const depositHandler = () => {
        open(<DepositChoice />);
    }
    const withdrawalHandler = () => {
        open(<Withdrawal />);
    }

    useEffect(() => {
        headerModalUpdate !== 'wallet' ? setModalOpen(false) : setModalOpen(true);
    }, [headerModalUpdate]);


    const handleModal = () => {
        setHeaderModalUpdate('wallet')
        setModalOpen(!modalOpen)
    }

    return (
        <div>
            <button onClick={handleModal} className={`header-button wallet-${modalOpen ? 'active' : 'inactive'}`}>
                <i className="icon wallet"></i>
                <span>{userBalance}₽</span>
            </button>
            <div className={`wallet-absolute ${modalOpen ? 'opened' : 'closed'}`}>
                <button onClick={depositHandler} className="header-money-button">
                    <i className="icon deposit"></i>
                    <span>{texts.deposit}</span>
                </button>
                <button onClick={withdrawalHandler} className="header-money-button">
                    <i className="icon withdrawal"></i>
                    <span>{texts.withdrawal}</span>
                </button>
            </div>

        </div>
    )
}

export default Wallet;